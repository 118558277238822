.gfield_label,label {
  display: none;
}
.gform_wrapper {
  .gfield_error .gfield_label , .validation_message,.validation_error{
    color: #790000;
  }
}
.gform_body {
  .gform_fields {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: 100%;

      &:first-child {
        .ginput_container {
          display: flex;
          justify-content: space-between;
          @include respond-below(sm) {
            flex-wrap: wrap;
          }
        }

        span {
          width: calc(50% - 20px);
          @include respond-below(sm) {
            width: 100%;
            &:first-child{
              margin-bottom: 17px;
            }
          }
        }

        input {
          width: 100%;
        }
      }

      &:not(:first-child):not(:last-child) {
        width: calc(50% - 20px);
        float: left;
        @include respond-below(sm) {
          width: 100%;
        }
        input {
          width: 100%;
        }
      }
    }


  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #b2b2b2;
    @include font-size(14);
  }

  :-ms-input-placeholder { /* IE 10+ */
    color: #b2b2b2;
  }

  .gfield {
    margin-bottom: 17px;
  }

  select {
    padding: 0 12px;
    color: #1d1e21;
    height: 30px;
    width: 100%;
  }
}
