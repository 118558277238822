
.breadcrumb-wrap {
  position: relative;
}

.breadcrumb {
  padding: 15px 0;
  position: absolute;
  top: 0;
  left: 15px;
  z-index: 2;

  ul {
    list-style: none;
    margin: 0;
    display: inline-flex;

    li {
      color: #7d7d7d;

      a {
        color: #7d7d7d;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.banner {
  &__content {
    max-width: 430px;
    margin: 130px 0 90px;
    @media (max-width: 640px) {
      max-width: 100%;
      margin: 100px 0 60px;
    }
  }

  &__image {
    max-width: 880px;
    width: 100%;
    transform: translateX(80px);
    @include respond-below(xxxl) {
      transform: translateX(15px);
    }
    @media (max-width: 640px) {
      display: none;
    }

    img {
      height: 100%;
      object-fit: cover;
      object-position: left;
    }
  }
}


.image-content-block {

  .wrapper {
    padding: 90px 0 60px;
    overflow: hidden;
    background: #fff;
    border-top-left-radius: 130px;
    display: flex;
    align-items: center;
    @include respond-below(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .image-block {
    margin-left: -90px;
    @include respond-below(md) {
      margin: 0 0 0 50px;
      max-width: 310px;
    }
  }

  .content-block {
    max-width: 630px;
    margin: 0 30px 0 60px;
    @include respond-below(lg) {
      max-width: 440px;
    }
    @include respond-below(md) {
      margin: 30px;
      max-width: 100%;
    }
  }
}

.c-services-block {
  padding: 120px 0;

  .section-heading {
    max-width: 630px;
    margin: 0 auto 80px;
  }

  + .c-highlighted-block {
    margin-top: 20px;
  }
}

.service-cards {
  display: flex;
  flex-wrap: wrap;
  @include respond-below(lg) {
    margin: 0 -15px;
  }
  @include respond-below(sm) {
    justify-content: center;
  }
  @include respond-above(md) {
    &.card--middle {
      justify-content: center;
    }
  }

  .card__image {
    max-width: 60px;
    max-height: 60px;
    width: 100%;
  }

  .card {
    margin: 0 25px 25px 0;
    border-radius: 13px;
    box-shadow: 0 0 20px 0 rgba(54, 57, 68, 0.03);
    border: solid 1px #e4e4e4;
    padding: 25px 20px;
    max-width: 380px;
    width: calc(33.3% - 50px);
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include respond-below(lg) {
      margin: 0 15px 25px;
      width: calc(33.3% - 30px);
    }
    @include respond-below(md) {
      width: calc(50% - 30px);
    }
    @include respond-below(sm) {
      width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(6n-2) {
      margin-left: 70px;
      @include respond-below(lg) {
        margin-left: 15px;
      }
    }

    &__heading {
      margin-top: 15px;
      max-width: 300px;
    }

    &__content {
      margin-bottom: 50px;
    }
  }

  .underline {
    margin-bottom: 30px;

    &:after {
      height: 4px;
      bottom: -10px;
    }
  }

  a {
    text-decoration: none;
    margin-top: auto;
  }
}


.c-testimonial-inner {
  .wrapper {
    border-top-right-radius: 130px;
    background-color: #fff;
    padding: 100px 0 130px;
    background-size: contain !important;
    background-position: right !important;
  }

  .testimonial-slider {
    margin: 0;
    text-align: left;
    position: relative;
    z-index: 3;
  }

  .author__name {
    font-weight: bold;
    margin-bottom: 0;
    position: relative;

    &:after {
      content: '';
      height: 4px;
      width: 105px;
      background: #333;
      position: absolute;
      top: -15px;
      left: 0;
    }
  }

  .author__designation {
    font-family: $font-sec;
    font-weight: 600;
    color: #7d7d7d;
  }

  .slider-nav {
    padding: 0 50px;
    position: absolute;
    left: 0;
    bottom: -55px;

    svg {
      cursor: pointer;
      margin-right: 15px;
      color: #333;
      transition: 0.3s ease;

      &:hover {
        opacity: 0.38;
      }
    }
  }
}


.c-highlighted-block {
  margin: 165px 0 100px;
  text-align: center;
  @include respond-below(lg) {
    + .get-started {
      .content-block {
        padding-top: 0;
      }
    }
  }

  .card__image {
    max-width: 92px;
    width: 100%;
  }

  .heading-block {
    max-width: 725px;
    margin: 0 auto;
  }

  .cards {
    margin: 0 -15px;
    flex-wrap: nowrap;

    @include respond-below(md) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  a.card {
    text-decoration: none;

    p {
      color: #7D7D7D;
    }
  }

  .card {
    max-width: 380px;
    text-align: center;
    align-items: center;
    margin: 0 30px 30px;
    @include respond-below(lg) {
      margin: 0 15px 30px;
    }
    @include respond-below(md) {
      max-width: calc(50% - 30px);
    }
    @include respond-below(sm) {
      max-width: 380px;
    }

    &:last-child {
      margin-right: 30px;
      @include respond-below(lg) {
        margin-right: 15px;
      }
    }

    &__heading {
      margin-bottom: 0;
    }


  }
}

.c-clients {
  margin: 100px 0;

  &.bg-border--left {
    margin: 0;

    .wrapper {
      padding: 100px 0;
      background: #fff;
      border-top-left-radius: 130px;
    }
  }
}

.clients-logo {
  margin: 0 -12px;
  @include respond-below(sm) {
    margin: 0 -7px;
  }

  .client-logo {
    max-width: calc(20% - 24px);
    width: 100%;
    margin: 0 12px 24px;
    @include respond-below(lg) {
      max-width: calc(25% - 24px);
    }
    @include respond-below(md) {
      max-width: calc(33.3% - 24px);
    }

    @include respond-below(sm) {
      max-width: calc(33.3% - 14px);
      margin: 0 7px 7px;
    }
  }
}


.c-info-listing {
  .wrapper {
    background: #fff;
    border-top-left-radius: 130px;
    padding: 100px 0;
  }
}

.info-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -25px;

  .info-card {
    max-width: 280px;
    width: 100%;
    margin: 25px;

    &.info-card--disabled {
      img {
        filter: grayscale(100%);
        opacity: 0.4;
      }

      .card__heading {
        color: #b2b2b2;

        &:after {
          background: #b2b2b2;
        }
      }

      .card__description {
        color: #b2b2b2;
      }

      .link-readmore {
        color: #b2b2b2;
        pointer-events: none;
      }
    }

  }

  img.card__image {
    max-width: 52px;
  }

  .card__heading {
    @include font-size(20);
    margin: 20px 0 35px;

    &:after {
      bottom: -15px;
      height: 4px;
    }
  }

  .link-readmore {
    text-decoration: none;
  }

  .card__description {
    margin-bottom: 15px;
  }
}

.general-block {

  .wrapper {
    padding: 110px 15px;
    max-width: 670px;
    margin: 0 auto;
    text-align: center;
  }

  .section-heading {
    margin-bottom: 50px;
  }

  p {
    margin-bottom: 40px;
  }

  &--light {
    color: #fff;

    .section-heading {
      color: #fff;

      &:after {
        background: #fff;
      }
    }

    + .c-blog {
      margin-top: 75px;
    }
  }

  &--small {
    .wrapper {
      padding: 70px 0 50px;
    }
  }
}

.general-block--bg {
  .bg-wrap {
    background: #fff;
    border-top-left-radius: 200px;
  }

  &.bg-border--right {
    .bg-wrap {
      border-top-left-radius: 0;
      border-top-right-radius: 200px;
    }
  }
}

.c-info-listing {
  .wrapper--no-bg {
    background: transparent;
    border: 0;
  }
}

.c-blog {
  &.border--left {
    .wrapper {
      border-top-left-radius: 130px;
      border-top-right-radius: 0;
    }
  }
}

.suggested-insights {
  .card {
    padding-top: 35px;
  }
}

.c-highlighted-block {
  &.highlighted-block-bg {
    margin: 0;

    .wrapper {
      padding: 120px 0 90px;
      background: #fff;
      border-top-left-radius: 130px;
    }
  }
}

.c-image-content-block {
  .wrapper {
    display: flex;
    justify-content: space-between;
  }

  .content-block {
    padding: 110px 20px 50px 80px;
    max-width: 720px;
    //margin-bottom: 100px;
    @include respond-below(md) {
      padding: 60px 20px;
      margin-bottom: 0;
    }
  }

  .image-block {
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      object-position: left;
      max-height: 690px;
    }

    &.left-overlay-svg {
      img {
        object-position: right;
      }
    }

    @include respond-below(lg) {
      display: none;
    }
  }

  &:last-child {
    .content-block {
      //margin-bottom: 100px;
    }
  }
}

.form_banner_wrap {

  .banner__form {
    padding: 130px 0;
    margin-left: auto;
    @include respond-below(lg) {
      margin-left: 0;
      padding: 0;
    }

    > div {
      padding-left: 30px;
      @include respond-below(lg) {
        padding-left: 0;
      }
    }
  }

  @include respond-below(lg) {
    flex-direction: column;
  }
}

.c-image-content-block + .callout-block {
  //margin-top: -160px;
  z-index: 22;
  position: relative;
  @include respond-below(lg) {
    margin: 0;
  }
}

.page-interior-consulting {
  @include font-size(16);
  color: #4c4949;
}

.left-image-content-block {
  .wrapper {
    display: flex;
    justify-content: space-between;
  }

  .content-block {
    max-width: 750px;
    padding: 170px 80px 170px 30px;
  }

  .image-block {
    img {
      height: 100%;
      object-fit: cover;
      object-position: right;
    }
  }
}

.right-image-content-block {
  .wrapper {
    display: flex;
    justify-content: space-between;
  }

  .content-block {
    max-width: 750px;
    padding: 170px 30px 170px 80px;
  }

  .image-block {
    img {
      height: 100%;
      object-fit: cover;
      object-position: left;
    }
  }

  &.bg-border--right {
    .wrapper {
      background: #fff;
      border-top-right-radius: 130px;
      overflow: hidden;
    }
  }
}

.quick-fact-block {
  padding: 120px 0 90px;
}

.fact-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -15px;

  .fact-card {
    max-width: 190px;
    width: 100%;
    background: #fff;
    padding: 20px 20px 40px;
    text-align: center;
    border-radius: 13px;
    box-shadow: 0 0 20px 0 rgba(54, 57, 68, 0.03);
    border: solid 1px #e4e4e4;
    margin: 0 15px 30px;

    img {
      max-width: 61px;
      width: 100%;
    }
  }

  .count-number {
    @include font-size(24);
    color: #333;
    font-family: $font-sec;
    font-weight: bold;
    margin: 10px 0 5px;
    line-height: 1;
  }
}

.callout-block {
  .wrapper {
    background: #fff;
    padding: 110px 0 75px;
    border-top-right-radius: 130px;

    .container {
      display: flex;
      justify-content: space-between;

      &:not(last-child) {
        margin-bottom: 50px;
      }

      @include respond-below(lg) {
        flex-direction: column;
      }
    }
  }

  .content-block {
    margin-right: 80px;
    @include respond-below(xl) {
    }
    @include respond-below(lg) {
      margin: 0 0 30px;
    }
  }
}

.wrapper {
  overflow: hidden;
}

.guide-block {
  border-radius: 13px;
  box-shadow: 0 0 20px 0 rgba(54, 57, 68, 0.03);
  border: solid 1px #e4e4e4;
  background-color: #f4f8fb;
  max-width: 375px;
  width: 100%;
  padding: 35px;
  color: #223a73;
  align-self: flex-start;
  @include font-size(18);
  @include respond-below(lg) {
    align-self: center;
  }

  p {
    margin-bottom: 20px;
  }

  .section-heading {
    margin-bottom: 40px;
  }
}

.get-started__contact {
  margin-top: -120px;
  position: relative;

  .wrapper {
    background: #fff;
    border-top-right-radius: 130px;
    padding: 100px 0;

    .container {
      display: flex;
      justify-content: space-between;
      @include respond-below(lg) {
        flex-direction: column-reverse;
      }
    }
  }

  .content-block {
    padding: 0;
    @include respond-below(xl) {
      max-width: 520px;
    }
    @include respond-below(lg) {
      max-width: 100%;
    }
  }

  .map-address {
    @include font-size(24);
    color: #223a73;
    font-family: $font-sec;
    font-weight: bold;
    margin-top: 20px;
    max-width: 420px;
  }

}

.map-block {
  max-width: 550px;
  max-height: 560px;
  height: 100%;
  width: 100%;
  @include respond-below(lg) {
    max-width: 100%;
    margin: 60px 0;
  }
}

#map {
  width: 100%;
  height: 560px;
  background-color: grey;
}

.banner-contact {
  .banner__content {
    margin: 130px 0 200px;
  }
}

.page-interior-consulting {
  .banner__content {
    max-width: 470px;
    width: 100%;
  }
}

.banner__image {
  position: relative;
  overflow: hidden;
  text-align: right;

  .svg-cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.c-testimonial-inner {
  .wrapper {
    position: relative;
  }
}

.right-decor-bg {
  max-width: 610px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  @include respond-below(lg) {
    display: none;
  }

  img {
    position: absolute;
    right: 0;
    top: 0;
    object-fit: cover;
    height: 100%;
  }

  svg {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 610px;
    z-index: 2;
  }
}

.left-overlay-svg {
  position: relative;

  .svg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  + .content-block {
    padding-left: 20px;
    padding-right: 110px;
  }
}

.right-overlay-svg {
  position: relative;

  .svg-overlay {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
}


.single-container {
  max-width: 1080px;
  margin: 0 auto 120px;
  padding: 0 15px;
  overflow: hidden;
  color: #4c4949;
  @include font-size(18);

  .wrapper {
    max-width: 770px;
    margin: 0 auto;
    overflow: visible
  }

  .overflow-image {
    margin: 60px -140px;
  }

  ul {
    margin: 20px 0 20px 20px;
  }

  li {
    margin-bottom: 10px;
  }

  h1 {
    @include font-size(36);
  }

  h2 {
    @include font-size(30);
  }

  h3 {
    @include font-size(24);
  }

  .post {
    margin-top: 75px;
  }

}

.blog-banner {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: url(assets/images/blog-banner-overlay.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(142, 152, 177, 0.6) 0%, rgba(35, 57, 116, 0.48) 100%), linear-gradient(180deg, #233974 0%, rgba(35, 57, 116, 0.8) 100%);
    mix-blend-mode: multiply;
    opacity: 0.75;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  img {
    width: 100%;
    position: absolute;
    top: 0;
    object-fit: cover;
    height: 100%;
  }

  &__content {
    max-width: 770px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding: 185px 0 120px;

    h1 {
      @include font-size(48);
      font-family: $font-sec;
      font-weight: bold;
      line-height: 1.3;
      color: #fff;
    }
  }
}

span.posted-on {
  color: #fff;
}

.related-posts-block {
  margin: 120px 0;

  .card {
    background-color: #fff;
    padding: 50px 25px 30px;
  }

  .btn-group {
    margin-top: 70px;
    text-align: center;
  }

  .section-heading {
    margin-bottom: 90px;
  }
}

.blog-title {
  padding: 110px 0 40px;
  @include respond-below(md) {
    padding: 50px 0 20px;
  }
}

.sticky-post {
  border-radius: 13px;
  box-shadow: 0 0 20px 0 rgba(54, 57, 68, 0.03);
  border: solid 1px #e4e4e4;
  background: #fff;
  padding: 25px;
  display: flex;
  align-items: center;
  position: relative;
  @include respond-below(md) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.sticky-post__image {
  max-width: 640px;
  width: 100%;

  + .sticky-post__content {
    margin-left: 50px;
    @include respond-below(md) {
      margin: 0;
    }
  }

  @include respond-below(md) {
    margin-top: 20px;
  }
}

.sticky-post__content {
  padding: 20px 0;

  h3 {
    max-width: 360px;
    margin-bottom: 35px;

    &:after {
      height: 4px;
      width: 80px;
    }
  }

  p {
    max-width: 510px;
  }

  .link-readmore {
    margin-top: 30px;
    display: inline-block;
  }
}

.sc-ajax-filter {
  .blog-cards {
    flex-wrap: wrap;
  }

  .card {
    margin: 0 20px 60px;
  }

  .nav-filter {
    margin: 100px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      list-style: none;
      margin-bottom: 20px;

      &.active {
        a {
          color: #223a73;
          text-decoration: underline;
          pointer-events: none;
        }
      }
    }

    a {
      text-decoration: none;
      color: #a5a5a5;
      @include font-size(18);
      font-weight: bold;
      padding: 0 25px;
    }
  }

  .loading {
    text-align: center;
    padding-bottom: 100px;
    font-weight: bold;
    @include font-size(20);
  }
}

ul.pagination {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 40px;

  li {
    font-family: $font-sec;
    @include font-size(16);

    span {
      font-weight: bold;
      color: #223a73;
      padding: 5px;
      display: inline-block;
    }

    a {
      font-family: $font-sec;
      text-decoration: none;
      padding: 5px;
      display: inline-block;
    }
  }
}

.search-results {
  .blog-cards {
    flex-wrap: wrap;

    .card {
      margin: 0 20px 60px;
    }
  }

  nav.navigation.posts-navigation {
    text-align: center;
    padding: 20px 0 60px;
  }

  .posts-navigation .nav-previous, .post-navigation .nav-previous {
    float: none;
  }

  .posts-navigation .nav-next {
    float: none;
    text-align: center;
  }
}

.no-results.not-found {
  margin-bottom: 60px;
  text-align: center;
}

.page-404 {
  background: #233974f0;
  color: #fff;

  .wrapper {
    max-width: 500px;
    margin: 0 auto;
    padding: 200px 0;
    text-align: center;
    @include font-size(24);
    font-weight: 500;
  }

  .icon-404 {
    text-align: center;
    margin-bottom: 20px;
  }

  a {
    color: #fff;
  }

  .link-404 {
    margin-top: 20px;
  }
}

.archive {
  .site-footer {
    margin-top: 50px;
  }
}

.page-template-default {
  .c-highlighted-block .cards {
    justify-content: center;
    flex-wrap: wrap;

    .card {
      max-width: calc(25% - 60px);
    }
  }
}
