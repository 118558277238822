
.btn {
  padding: 14px 25px;
  @include font-size(16);
  text-transform: capitalize;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  transition: 0.3s ease;
  font-family: $font-sec;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;

  + .btn {
    margin-left: 20px;
    @include respond-below(mb) {
      margin: 20px 0 0;
    }
  }

  &--primary {
    box-shadow: 4px 4px 20px 0 rgba(41, 46, 60, 0.14);
    background-image: linear-gradient(to top, #f6871f, #ffa34d);

    &:hover {
      background: linear-gradient(to top,  #ffa34d,#f6871f);
      color: #fff;
    }
  }

  &--secondary {

    border-color: #f6871f;
    color: #f6871f;

    &:hover {
      background: #f6871f;
      color: #fff;
    }
  }

  &--black {
    box-shadow: 4px 4px 20px 0 rgba(41, 46, 60, 0.14);
    border: solid 3px #333;
    color: #333;
    &:hover, &:active, &:focus {
      background: #333;
      color: #fff;
    }
  }
  &--blue {
    box-shadow: 4px 4px 20px 0 rgba(41, 46, 60, 0.14);
    border: solid 3px #223a73;
    color: #223a73;
    &:hover, &:active, &:focus {
      background: #223a73;
      color: #fff;
    }
  }
  &--sky-blue {
    box-shadow: 4px 4px 20px 0 rgba(41, 46, 60, 0.14);
    border: solid 3px #1fcff6;
    color: #1fcff6;
    &:hover, &:active, &:focus {
      background: #1fcff6;
      color: #fff;
    }
  }
  &--disabled{
    border: solid 3px #a5a5a5;
    color: #a5a5a5;
    cursor: default;
    &:hover, &:active, &:focus {
      border-color: #a5a5a5;
      color: #a5a5a5;
    }
  }
}
