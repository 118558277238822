.no-sidebar {
	.content-area {
		float: none;
		margin-left: auto;
		margin-right: auto;
	}

	.site-main {
		margin-right: 0;
	}
}
