@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  font-size: 62.5%;
}

*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit;
}

body {
  background: $color__background-body; /* Fallback for when there is no custom background color defined. */
  -webkit-font-smoothing: antialiased;
}

hr {
  background-color: $color__background-hr;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
}

img {
  height: auto; /* Make sure images are scaled correctly. */
  max-width: 100%; /* Adhere to container width. */
  vertical-align: middle;
}

figure {
  margin: 1em 0; /* Extra wide images within figure tags don't overflow the content area. */
}

p {
  margin: 0 0 10px;
}

dfn, cite, em, i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

pre {
  background: $color__background-pre;
  font-family: $font__pre;
  @include font-size(0.9375);
  line-height: $font__line-height-pre;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code, kbd, tt, var {
  font-family: $font__code;
  @include font-size(0.9375);
}

abbr, acronym {
  border-bottom: 1px dotted $color__border-abbr;
  cursor: help;
}

mark, ins {
  background: $color__background-ins;
  text-decoration: none;
}

big {
  font-size: 125%;
}

/*--------------------------------------------------------------
# Lists
--------------------------------------------------------------*/
ul, ol {
  margin: 0 0 0 20px;
  padding: 0;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

/*--------------------------------------------------------------
# Tables
--------------------------------------------------------------*/
table {
  margin: 0 0 1.5em;
  width: 100%;
}

/*--------------------------------------------------------------
# Headings
--------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  clear: both;
  margin: 0 0 10px;
}

/*--------------------------------------------------------------
# Inputs and Buttons
--------------------------------------------------------------*/
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  color: $color__text-input;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgba(54, 57, 68, 0.03);
  border: solid 1px #e4e4e4;
  padding: 9px 18px;
  text-transform: capitalize;

  &:focus {
    color: $color__text-input-focus;
  }
}

select {
  border: 1px solid $color__border-input;
}

textarea {
  width: 100%;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  padding: 14px 25px;
  @include font-size(16);
  text-transform: capitalize;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  transition: 0.3s ease;
  font-family: $font-sec;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 4px 4px 20px 0 rgba(41, 46, 60, 0.14);
  border: solid 3px #223a73;
  color: #223a73;
  background: transparent;

  &:hover {
    background: #223a73;
    color: #fff;
  }

  &:active,
  &:focus {
    background: #223a73;
    color: #fff;
  }
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */

  &:focus {
    background-color: $color__background-screen;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    clip-path: none;
    color: $color__text-screen;
    display: block;
    @include font-size(0.875);
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; /* Above WP toolbar. */
  }
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Links
--------------------------------------------------------------*/
a {
  color: $color__link;
  transition: 0.3s ease;

  &:visited {
    /* color: CurrentColor;*/
  }

  &:hover,
  &:focus,
  &:active {
    color: $color__link-hover;
  }

  &:focus {
    outline: thin dotted;
  }

  &:hover,
  &:active {
    outline: 0;
  }
}

/*--------------------------------------------------------------
# WordPress Default
--------------------------------------------------------------*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
  display: inline-block;
}

.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;

  img[class*="wp-image-"] {
    @include center-block;
  }

  .wp-caption-text {
    margin: 0.8075em 0;
  }
}

.wp-caption-text {
  text-align: center;
}

.comment-content a {
  word-wrap: break-word;
}

.bypostauthor {
  display: block;
}

.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;

  // Loops to enumerate the classes for gallery columns.
  @for $i from 2 through 9 {
    .gallery-columns-#{$i} & {
      max-width: map-get($columns, $i);
    }
  }
}

.gallery-caption {
  display: block;
}

/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation, /* Older / Newer Posts Navigation (always hidden) */
.infinite-scroll.neverending .site-footer { /* Theme Footer (when set to scrolling) */
  display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
  display: block;
}

.widget {
  margin: 0 0 1.5em;

  /* Make sure select elements fit in widgets. */
  select {
    max-width: 100%;
  }
}

svg {
  vertical-align: middle;
}

.text-center {
  text-align: center;
}

@media (max-width: 1440px) {
  .no-padding {
    padding: 0 !important;
  }
}

.highlighted-text {
  color: #223a73;
  @include font-size(14);
  font-weight: bold;
}


.underline {
  position: relative;
  margin-bottom: 70px;

  &.section-subheading {
    margin-bottom: 35px;
  }

  &:after {
    content: '';
    background: #333;
    height: 7px;
    width: 50px;
    position: absolute;
    bottom: -20px;
    left: 0;
  }

  &--center {
    text-align: center;

    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  @include font-size(12);
  font-weight: bold;
  font-family: $font-sec;
  text-transform: capitalize;
  color: #fff;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 6px 20px;
  display: inline-block;

  &--grey {
    background: #c4c4c4;
  }

  &--green {
    background: #91c840;
  }

  &--blue {
    background: #223a73;
  }
  &--plum {
    background: #993399;
  }
  &--darkblue {
    background: #1c2f40;
  }
  &--golddrop{
    background: #E68200;
  }
  &--lightyellow{
    background: #e7b900;
  }
  &--purpleheart{
    background: #6432a8;
  }
}

.link-readmore {
  color: #223A73;
  font-weight: bold;
  font-family: $font-sec;
  @include font-size(16);
}

.cards--flex {
  display: flex;
  justify-content: space-between;
}
figure.wp-block-image {
  margin: 60px 0;
}
/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
