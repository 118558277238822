.header-top {
  background: #233974;
  padding: 5px 0;
  position: relative;
  z-index: 11;

  ul {
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    li {
      margin-left: 25px;
    }
  }

  a {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    @include respond-below(sm) {
      span {
        display: none;
      }
    }

    &:hover {
      color: lighten(#223A73, 50%);
    }
  }

  .btn {
    background: #fff;
    color: #223A73;
    font-family: $font-sec;
    line-height: 1;
    padding: 5px 15px;
    border-radius: 26px;
    font-weight: 600;
    @include font-size(14);

    &:hover {
      background: lighten(#223A73, 10%);
      color: #fff;
    }
  }

  svg {
    margin-right: 10px;
  }
}

.site-header {
  position: relative;
  z-index: 10;
  padding: 10px 0 15px;

  &--light {
    background: #fff;

    i.arrow {
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='11' viewBox='0 0 19 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.19214L9.5 9.19214L17.5 1.19214' stroke='%23333' stroke-width='2'/%3E%3C/svg%3E%0A");
      }

    }

    .main-navigation {
      a {
        color: #333;
      }

      ul {
        ul {
          a {
            color: #fff;
          }
        }
      }
    }

    .search-trigger {
      color: #333;
    }

    .menuBar {
      background-color: #333;
    }

    .nav__visible & {
      .main-navigation {
        a {
          color: #fff;
        }
      }

      .menuBar {
        background-color: #fff;
      }
    }
  }


}

.logo {
  a {
    color: #fff;

    .site-header--light & {
      color: #333;
    }
  }


}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1111;
  background-color: #233974;

  + .page-wrap {
    padding-top: 103px;
  }
}
