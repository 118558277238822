/*!
Theme Name: Surety System
Theme URI: https://www.suretysystems.com/
Author: Author Name
Author URI:
Description: WordPress theme created for Surety System by Company Name.
Version:      1.0.0
Text Domain: surety-system
*/

@import "helpers/variables";
@import "helpers/mixins-master";

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
@import "base/all";

/*--------------------------------------------------------------
# components
--------------------------------------------------------------*/
@import "components/all";

/*--------------------------------------------------------------
# helpers
--------------------------------------------------------------*/
@import "helpers/all";

/*--------------------------------------------------------------
# layout
--------------------------------------------------------------*/
@import "layout/all";

/*--------------------------------------------------------------
# pages
--------------------------------------------------------------*/
@import "pages/all";

/*--------------------------------------------------------------
# Themes
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Vendors
--------------------------------------------------------------*/
@import "vendors/all";
