.main-navigation {
  margin-left: auto;
  @include respond-below(lg) {
    position: fixed;
    background: #233974;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.1s ease;
    overflow-y: auto;
  }

  @media (min-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;

    ul {
      display: none;

      @media (min-width: 992px) {
        display: block !important;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
        float: left;
        position: absolute;
        top: 100%;
        left: -999em;
        z-index: 99999;
        padding: 0;
        background: #233974;
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 10px 10px 10px;
          border-color: transparent transparent #233974 transparent;
          left: 80px;
          top: -10px;
        }

      }

      ul {
        left: -999em;
        top: 0;

      }

      li {
        &:hover > ul,
        &.focus > ul {
          left: 100%;
        }

        &:last-child {
          a {
            border-bottom: 0;
          }
        }
      }

      a {
        width: 220px;
        padding: 15px 10px;
        font-weight: 500;
        border-bottom: 1px solid rgba(#ccc, 0.5);
        @media (min-width: 992px) {
          color: #fff;
          &:hover {
            background: darken(#233974, 5%);
          }
        }
        @include respond-below(lg) {
          display: block;
          width: 100%;
          padding: 15px;
          @include font-size(20);
        }
      }

      :hover > a,
      .focus > a {
      }

      a:hover,
      a.focus {
      }
    }

    li:hover > ul,
    li.focus > ul {
      left: auto;
    }
  }

  li {
    position: relative;
    cursor: pointer;
    @media (min-width: 992px) {
      float: left;
    }

    &:hover > a,
    &.focus > a {
      a {

      }
    }

  }

  a {
    font-family: $font-sec;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    text-transform: capitalize;
    line-height: 1;
    padding: 8px 15px 8px 30px;
    transition: 0.3s ease;
    @include respond-below(lg) {
      width: calc(100% - 50px);
      font-size: 24px;
      padding: 15px;
    }

    &:hover {

    }
  }

  .current_page_item > a,
  .current-menu-item > a,
  .current_page_ancestor > a,
  .current-menu-ancestor > a {
    color: #233974;
  }
}

.comment-navigation,
.posts-navigation,
.post-navigation {

  .site-main & {
    margin: 0 0 1.5em;
    overflow: hidden;
  }

  .nav-previous {
    float: left;
    width: 50%;
  }

  .nav-next {
    float: right;
    text-align: right;
    width: 50%;
  }
}

.menu-item-has-children {
  @include respond-below(lg) {
    border-bottom: 1px solid #ccc;
  }

  i.arrow {
    height: 11px;
    width: 19px;
    position: relative;
    display: inline-block;
    @include respond-below(lg) {
      transform: rotate(-90deg);
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 11px;
      width: 19px;
      background-image: url("data:image/svg+xml,%3Csvg width='19' height='11' viewBox='0 0 19 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.19214L9.5 9.19214L17.5 1.19214' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E%0A");
      background-size: cover;
    }

  }

  i.arrow.open {

    transform: rotate(0deg);

  }
}

.menu-primary-navigation-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  transform: translateX(250px);
  background: #b4b655;
  z-index: 1;
  @media (min-width: 992px) {
    margin-top: 20px;
    position: relative;
    transform: translateX(0);
    width: 100%;
    background: #fff;
    .arrow {
      display: none;
    }
  }
}

.menuBar__container {
  position: relative;
  width: 20px;
  height: 14px;
  -webkit-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
  z-index: 2;
  margin-left: 20px;
  display: none;
  @include respond-below(lg) {
    display: block;
  }
}

.menuBar {

  height: 2px;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 1px;
  -webkit-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;


  &:first-child {
    top: 0;
    margin-top: 0;
  }

  &:last-child {
    top: auto;
    bottom: 0;
    margin-top: 0;
  }
}

.nav__visible {
  .main-navigation {
    top: 36px;
  }

  .site-header.site-header--light {
    i.arrow {
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='11' viewBox='0 0 19 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.19214L9.5 9.19214L17.5 1.19214' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E%0A");
      }

    }
  }
}

.nav__visible .menuBar:first-child {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 6px;
}

.nav__visible .menuBar:nth-child(2) {
  opacity: 0;
}

.nav__visible .menuBar:last-child {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  bottom: auto;
  top: 6px;
}

div#primary-menu {
  @include respond-below(lg) {
    margin-top: 43px;
    padding: 0 30px;
  }
}

body.nav__visible {
  overflow: hidden;
}


.search-trigger {
  margin-left: 30px;
  cursor: pointer;
  color: #fff;

  @include respond-below(lg) {
    margin-left: auto;
  }
}


.slideout-search {
  background: #fff;
  padding: 30px 0;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform .5s cubic-bezier(.39, .58, .36, .89), left .3s ease, opacity .3s ease, -webkit-transform .5s cubic-bezier(.39, .58, .36, .89);
  transform: scale(1, 1) translate(0, -150%);

  input {
    text-transform: capitalize;
    font-weight: 500;
    @include font-size(24);
    width: 90%;
    padding: 20px;
    display: block;
    outline: 0;
    border: 0;
    border-bottom: 1px solid transparent;
    border-radius: 0;
    box-shadow: none;

  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #c5c4c9;
  }

  .search-form__field {
    width: 100%;
  }

  .slideout-search__close {
    color: #c5c4c9;
  }
}

.slideout-search.slideout-toggle {
  transform: scale(1, 1) translate(0, 0) !important;
  z-index: 9999;
}

.menu-primary-menu-container {
  @include respond-below(lg) {
    margin-top: 60px;
    position: relative;

  }
}

.nav__visible {

  .menu-primary-menu-container {
    @include respond-below(lg) {
      &:after {
        content: '';
        background: #233974;
        top: 34px;
        width: 100%;
        height: 70px;
        position: fixed;
        left: 0;
        z-index: 1;
      }
    }
  }
}
