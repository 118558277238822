/*

*/

@font-face {
  font-family: 'Trebuchet MS';
  src: url('assets/fonts/Trebuchet-BoldItalic.woff2') format('woff2'),
  url('assets/fonts/Trebuchet-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Trebuchet MS';
  src: url('assets/fonts/TrebuchetMS-Bold.woff2') format('woff2'),
  url('assets/fonts/TrebuchetMS-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trebuchet MS';
  src: url('assets/fonts/TrebuchetMS.woff2') format('woff2'),
  url('assets/fonts/TrebuchetMS.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trebuchet MS';
  src: url('assets/fonts/TrebuchetMS-Italic.woff2') format('woff2'),
  url('assets/fonts/TrebuchetMS-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}


