.hero {
  position: relative;
  margin-top: -70px;
}

.hero {
  position: relative;
  overflow: hidden;
  color: #fff;
  padding: 190px 0;

  .btn-group {
    @include respond-below(mb) {
      display: inline-flex;
      flex-direction: column;
    }
  }

  &:after {
    content: '';
    background: linear-gradient(180deg, rgba(142, 152, 177, 0.6) 0%, rgba(35, 57, 116, 0.48) 100%), linear-gradient(180deg, #233974 0%, rgba(35, 57, 116, 0.8) 100%);
    mix-blend-mode: multiply;
    opacity: 0.75;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: top center;
  }

  .hero__content {
    position: relative;
    z-index: 2;
    max-width: 570px;
  }

  p {
    line-height: 2.05;
    color: #fcfafa;
    margin-bottom: 30px;
  }

  h1 {
    color: #fff;
    text-transform: capitalize;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 20px;
  }
}

.section-heading {
  @include respond-below(sm) {
    @include font-size(26);
  }
}

.heading {

  &-bg {
    box-shadow: 10px 4px 20px 0 rgba(54, 57, 68, 0.03);
    background: #fff;
    padding: 80px 0;
    margin-top: -60px;
    z-index: 2;
    position: relative;
    border-bottom-right-radius: 200px;
    border-top-right-radius: 30px;
    max-width: 1360px;
    @include respond-below(xxl) {
      margin-right: 40px;
    }
    @include respond-below(sm) {
      padding: 30px 0 50px;
      margin-right: 30px;
    }
  }

  h2 {
    margin: 0;
    line-height: 1;
  }

  &--decor {
    position: relative;

    &:after {
      content: '';
      background: #333;
      height: 7px;
      width: 50px;
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}


.cards {
  padding: 65px 0 0;
  flex-wrap: wrap;

  + .cards {
    padding: 0 0 60px;
  }

  .card {
    border-radius: 13px;
    box-shadow: 0 0 20px 0 rgba(54, 57, 68, 0.03);
    border: solid 1px #e4e4e4;
    padding: 30px 20px 25px;
    background: #fff;
    max-width: 250px;
    width: 100%;
    margin-right: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }

  .card__image {
    margin-bottom: 20px;
  }

  .card__heading {
    margin-bottom: 40px;

    &--decor {
      position: relative;

      &:after {
        content: '';
        background: #333;
        height: 4px;
        width: 50px;
        position: absolute;
        bottom: -15px;
        left: 0;
      }
    }
  }

  .card__description {
    margin-bottom: 25px;
  }

  .btn {
    margin-top: auto;
  }
}

.c-what-we-offer {
  .cards {
    margin: 0 -15px;
    display: flex;
    @include respond-below(lg) {
      justify-content: center;
    }

    .card {
      margin: 0 15px 40px;
      width: calc(25% - 30px);

      &:nth-child(5) {
        margin-left: auto;
      }

      @include respond-below(lg) {
        width: calc(33.3% - 30px);
        &:nth-child(5) {
          margin-left: 15px;
        }
      }
      @include respond-below(md) {
        width: calc(50% - 30px);
      }
      @media (max-width: 576px) {
        width: 100%;
        max-width: 100%;
      }


    }
  }
}

.c-system-skinny {
  .wrapper {
    background: #fff;
    border-top-left-radius: 120px;
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .content-block {
    padding: 80px;
    max-width: 50%;
    @include respond-below(lg) {
      max-width: 100%;
    }
    @include respond-below(sm) {
      padding: 80px 40px;
    }
  }

  .image-block {
    max-width: 716px;
    @include respond-below(lg) {
      display: none;
    }

    img {
      height: 100%;
      object-fit: cover;
      object-position: left;
    }
  }
}


.timeline {

  @include font-size(14);

  .timeline__content > * {
    width: 50%;
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__image {
    text-align: center;
  }

  h4 {
    @include font-size(24);
    text-transform: capitalize;
    border-bottom: 4px solid #C4C4C4;
    position: relative;

    &:after {
      content: '';
      height: 18px;
      width: 18px;
      border-radius: 50%;
      position: absolute;
      bottom: -10px;
    }
  }

  p {
    max-width: 280px;
    display: inline-block;
  }

  &__left {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 4px;
      background: #C4C4C4;
      top: 0;
      right: 0;
    }

    h4 {
      &:after {
        right: -7px;
      }
    }

    p {
      padding: 0 20px 20px 0;
    }
  }

  &__right {
    text-align: right;
    padding-top: 80px;

    h4 {
      &:after {
        left: -11px;

      }
    }

    p {
      padding: 0 0 20px 20px;
    }
  }

  &.timeline--last .timeline__left:before {
    height: 60%;
  }

  &--blue {
    h4 {
      color: #223a73;

      &:after {
        background: #223a73;
      }
    }

    svg {
      color: #223a73;
    }
  }

  &--yellow {
    h4 {
      color: #f6871f;

      &:after {
        background: #f6871f;
      }
    }

    svg {
      color: #f6871f;
    }
  }

  &--green {
    h4 {
      color: #91c840;

      &:after {
        background: #91c840;
      }
    }

    svg {
      color: #91c840;
    }
  }

}

.surety-timeline {
  margin-top: 60px;

  .btn-group {
    margin-top: 30px;
    text-align: center;
  }
}


.c-testimonial {
  padding: 65px 0;
}


.testimonial-slider {
  border-radius: 13px;
  box-shadow: 0 0 20px 0 rgba(54, 57, 68, 0.03);
  border: solid 1px #e4e4e4;
  margin: 0 auto;
  max-width: 950px;
  background: #fff;
  text-align: center;

  .slider-item {
    padding: 65px 50px 50px;
  }

  .testimonial__text {
    margin-bottom: 45px;
    position: relative;

    .icon-left-quote {
      position: absolute;
      left: -30px;
      top: -20px;
    }

    .icon-right-quote {
      position: absolute;
      bottom: -10px;
      right: -20px;
    }
  }

  .author__name {
    color: #223a73;
    @include font-size(24);
    text-transform: capitalize;
  }

  .author__designation {
    @include font-size(16);
    color: #312c2c;
    font-style: italic;
    font-weight: bold;
  }

  .testimonial__author {
    margin-bottom: 15px;
  }

  .slick-dots {
    bottom: -50px;
  }
}


.c-blog {
  .wrapper {
    padding: 75px 0;
    background: #fff;
    border-top-right-radius: 120px;
  }

  .section-heading {
    margin-bottom: 55px;
  }

  .btn-group {
    margin-top: 70px;
    text-align: center;
  }
}

.blog-cards {
  display: flex;
  justify-content: center;
  max-width: 1320px;
  margin: 0 auto;
  @include respond-below(md) {
    flex-wrap: wrap;
    justify-content: center;
  }

  .card {
    position: relative;
    border-radius: 13px;
    box-shadow: 0 0 20px 0 rgba(54, 57, 68, 0.03);
    border: solid 1px #e4e4e4;
    max-width: 380px;
    width: 100%;
    padding: 70px 25px 30px;
    margin: 0 20px;
    @include respond-below(lg) {
      margin: 0 10px;
    }
    @include respond-below(md) {
      width: calc(50% - 20px);
      max-width: 100%;
      margin: 0 10px 20px;
    }
    @include respond-below(sm) {
      width: calc(100% - 20px);
      max-width: 380px;
    }
  }

  h3 {
    margin: 20px 0 35px;
  }

  .underline {
    &:after {
      height: 4px;
      width: 80px;
      bottom: -20px;
    }
  }
}

.blog-nav {
  padding: 0 0 50px;

  ul {
    list-style: none;
    display: flex;
    @include font-size(18);
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    li {
      margin: 0 40px 20px;
    }

    a {
      color: #a5a5a5;
      line-height: 1;
      text-decoration: none;
      font-weight: bold;

      &:hover, &.is-active {
        color: #223a73;
        text-decoration: underline;
      }
    }
  }
}

li.active-cat {
  a {
    color: #223a73;
    text-decoration: underline;
  }
}
