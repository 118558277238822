body,
button,
input,
select,
optgroup,
textarea {
  color: $color__text-main;
  font-family: $font__main;
  @include font-size(14);
  line-height: $font__line-height-body;
  font-weight: normal;
  font-style: normal;
}

h1,h2,h3,h4,h5,h6{
  font-family: $font-heading;
  color: #333;
  line-height: 1.3;
}
h1{
  font-size: 64px;
}
h2{
  font-weight: bold;
  @include font-size(36);
}
h3{
  @include font-size(24);
}
h4{
  @include font-size(16);
  color: #223a73;
  font-weight: 600;
}
