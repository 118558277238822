// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
	font-size: $sizeValue * 1px;
	font-size: ($sizeValue / 10 ) * 1rem;
}

// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Clearfix
@mixin clearfix() {
	content: "";
	display: table;
	table-layout: fixed;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
	clear: both;
}

// Column width with margin
@mixin column-width($numberColumns: 3) {
	width: map-get( $columns, $numberColumns ) - ( ( $columns__margin * ( $numberColumns - 1 ) ) / $numberColumns );
}


%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}



//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––
// A map of breakpoints.
$breakpoints: ( xs: 0, mb: 375px, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl:1400px, xxxl:1440px);
//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  }
  @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  }
  @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
    // If one or both of the breakpoints don't exist.
  }
  @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower)==false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper)==false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
