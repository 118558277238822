/*
font-family: 'Work Sans', sans-serif;
font-family: 'Trebuchet MS';
*/
$color__background-body: #F4F8FB;
$color__background-screen: #f1f1f1;
$color__background-hr: #ccc;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;

$color__text-screen: #333;
$color__text-input: #333;
$color__text-input-focus: #333;
$color__link: #223a73;
$color__link-visited: darken($color__link,30%);
$color__link-hover: lighten($color__link, 10%);
$color__text-main: #7D7D7D;
$color__sec: #333;

$color__border-input: #ccc;
$color__border-abbr: #666;

$columns: (
        1: 100%,
        2: 50%,
        3: 33.33%,
        4: 25%,
        5: 20%,
        6: 16.66%,
        7: 14.28%,
        8: 12.5%,
        9: 11.11%
);

$columns__margin: 3.8%;

$size__site-main: 100%;
$size__site-sidebar: 25%;

$font__main: 'Trebuchet MS';
$font-sec: 'Work Sans', sans-serif;
$font-heading: 'Work Sans', sans-serif;

$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;
$font__line-height-body: 1.5;
$font__line-height-pre: 1.6;
