.site-footer {
  background: #223a73;
  padding: 120px 0 20px;
}

.main-footer {
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-info {
  max-width: 320px;

  .footer-logo {
    margin-bottom: 25px;
  }

  p {
    color: #fff;
    opacity: 0.4;
    @include font-size(12);
  }
}

.footer-contact {
  color: #fff;
  text-align: center;
  @include font-size(14);
  font-family: $font-sec;

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-social {
  list-style: none;
  margin: 50px 0 0;
  display: flex;
  align-items: center;

  li {
    margin: 0 12px;

    a {
      color: #fff;
      display: inline-block;
      transition: 0.2s ease;

      &:hover {
        color: #f6871f;
      }
    }
  }
}

.footer-menu {
  margin-left: 20px;
  @include respond-below(sm) {
    width: calc(33.3% - 20px);
    margin: 0 10px 30px;
  }
  @include respond-below(mb) {
    width: calc(50% - 20px);
  }

  h4 {
    color: #fff;
    font-family: $font-sec;
    font-weight: bold;
    @include font-size(16);
    margin-bottom: 10px;
  }

  ul {
    margin: 0;
    list-style: none;

    a {
      text-decoration: none;
      color: #fff;
      opacity: 0.8;
      transition: 0.1s ease;
      @include font-size(12);
      background-image: linear-gradient(currentColor, currentColor);
      background-repeat: no-repeat;
      background-position: center bottom 2%;
      background-origin: padding-box;
      background-size: 100% 0;

      &:hover {
        background-size: 100% 2px;
      }
    }

    &.footer-menu__three, &.footer-menu__five {
      margin-bottom: 20px;
    }
  }

  &:first-child {
    @include respond-below(xl) {
      margin-left: 0;
    }
    @include respond-below(sm) {
      margin-left: 10px;
    }
  }
}


.footer-menu-wrap {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 430px);
  @include respond-below(sm) {
    flex-wrap: wrap;
  }
}


@media (max-width: 1200px) {
  .main-footer {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .footer-info {
    max-width: 600px;
    text-align: center;
    margin-bottom: 80px;
  }

  ul.footer-social {
    justify-content: center;
  }

  .footer-menu-wrap {
    width: calc(100% - 30px);
  }


}


.get-started {
  .container--flex {
    @include respond-below(lg) {
      justify-content: center;
    }
  }

  .image-block {
    margin-right: 35px;
    position: relative;
    overflow: hidden;
    @include respond-below(lg) {
      display: none;
    }

    img {
      height: 100%;
      object-fit: cover;
      object-position: right;
    }
    .svg-cover {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }
  }

  .content-block {
    padding: 100px 0;
    max-width: 640px;
    margin: 0 15px;

  }

  .section-heading {
    margin-bottom: 40px;
  }

  .contact-form {
    margin-top: 50px;
  }
}

.cta-block {
  margin-bottom: -90px;

  .wrapper {
    margin: 0 125px 0 80px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include respond-below(xxl) {
      margin: 0 125px 0 30px;
    }
    @include respond-below(md) {
      margin: 0 15px;
    }
  }

  .heading-bg {
    padding: 70px 0 45px;
    @include respond-below(md) {
      padding: 30px 0;
    }
  }

  .section-heading {
    margin: 0 30px 20px 0;
    @include respond-below(xl) {
      margin-bottom: 40px;
    }
  }

  + .site-footer {
    padding-top: 210px;
  }
}
